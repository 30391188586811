
    export default {
        props: {
          page: {
            type: Object,
            default() {
                return {}
            }
          },
        },
        head() {
          if(this.$props.page.uri === '/podcast/') {
          return {
                script: [{ type: 'application/ld+json', json: this.structuredData }]
            }
          }
        },
        computed: {
          headerImage() {
            // console.log('THUMBNAIL',thumbnail)
            const dotIndex = this.page.thumbnail.lastIndexOf('.');
            const ext = this.page.thumbnail.substring(dotIndex);
            const options = {quality:60, width: '700px'}
            if (ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG' ){
              options.format = 'webp'
            }
            console.log('THUMBNAIL',this.page.thumbnail,options)
            const imgUrl = this.$img(this.$options.filters.cdn(this.page.thumbnail), options)
            return {
              backgroundImage: `url('${imgUrl}')`
            }
          },
          structuredData() {
          return {
            "@context": "http://schema.org",
            "@type": "PodcastSeries",
            "name": "I Taught English Abroad",
            "description": "A podcast by The TEFL Org where TEFL teachers share their teaching experiences from around the globe. Topics include the impact of identity on language teaching, career transitions, and teaching in various countries.",
            "url": "https://www.tefl.org/podcast/",
            "author": {
              "@type": "Person",
              "name": "Euan Davidson",
              "description": "Host of the I Taught English Abroad podcast, sharing stories from TEFL teachers worldwide."
            },
            "genre": "Education"
          }
        },
        }
    }
