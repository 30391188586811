
export default {
    props: {
      page: {
        type: Object,
        default() {
            return {}
        }
      },
    },
    computed: {
      headerImage() {
        // console.log('THUMBNAIL',thumbnail)
        const dotIndex = this.page.thumbnail.lastIndexOf('.');
        const ext = this.page.thumbnail.substring(dotIndex);
        const options = {quality:70, width: '1200px'}
        if (ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG' ){
          options.format = 'webp'
        }
        console.log('THUMBNAIL',this.page.thumbnail,options)
        const imgUrl = this.$img(this.$options.filters.cdn(this.page.thumbnail), options)
        return {
          backgroundImage: `url('${imgUrl}')`
        }
      },
    }
}
