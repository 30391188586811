
import {decode }from 'html-entities'
import parseShortcodes from '~/modules/shortcodes'
export default {
  /* eslint-disable */
  async asyncData({ $page, store, route, error }) {
    try {
      if ($page)
        return { page: $page };
      else
        return { page: await store.dispatch("pages/getPage", route.fullPath) };
    }
    catch (err) {
      error({
        statusCode: 404,
        message: 'Page could not be found',
      })
    }
  },
  //<link rel="alternate" href="https://www.tefl.org/courses/classroom/" hreflang="x-default" />
  head() {
    const title = this.page.seo.fullHead.child
      .map((el) => (el.tag === "title" ? el.child[0].text : null))
      .filter((el) => el)[0];
    const metas = this.page.seo.fullHead.child.filter((tag) => tag.node === "element");
    const metasArray = metas.filter((m) => m.tag === "meta").map((m) => m.attr);
    const linkArray = metas.filter((m) => m.tag === "link").map((m) => m.attr);
    const scriptArray = metas.filter((m) => m.tag === "script");
    const hreflangArray = this.page.hreflang;
    let meta = [];
    let link = [];
    let script = [];
    // let hreflang = []

    if(process.env.ROBOTS === 'LIVE'){
      metasArray.push({name:'robots', content: `${this.page.seo.metaRobotsNoindex}, ${this.page.seo.metaRobotsNofollow}`})
    } else {
      metasArray.push({name:'robots', content:'noindex, nofollow'})
    }
    for (let i = 0; i < metasArray.length; i++) {
      let obj = {};
      if (metasArray[i].name)
        obj.name = metasArray[i].name;
      if (metasArray[i].property)
        obj.property = metasArray[i].property;
      obj.content =
        typeof metasArray[i].content === "string"
          ? metasArray[i].content.replace(/(?<!\w-)\b\w+\.tefl\.org\b/g, "www.tefl.org")
          : metasArray[i].content.join(" ");
      meta.push(obj);
    }
    for (let i = 0; i < linkArray.length; i++) {
      let obj = {};
      obj.rel = linkArray[i].rel;
      obj.href = linkArray[i].href.replace(/(?<!\w-)\b\w+\.tefl\.org\b/g, "www.tefl.org");
      link.push(obj);
    }
    for (let i = 0; i < scriptArray.length; i++) {
      let obj = {};
      obj.class = scriptArray[i].attr.class;
      obj.type = scriptArray[i].attr.type;
      obj.innerHTML = `${scriptArray[i].child[0].text.replace(/(?<!\w-)\b\w+\.tefl\.org\b/g, "www.tefl.org")}`;
      script.push(obj);
    }
    if(hreflangArray){
      for (let i = 0; i < hreflangArray.length; i++) {
        let obj = {};
        obj.rel = hreflangArray[i].rel;
        obj.hreflang = hreflangArray[i].hreflang;
        obj.href = hreflangArray[i].href;
        link.push(obj);
      }
    }
    return {
      title: decode(title),
      meta: meta,
      link: link,
      script: script,
      __dangerouslyDisableSanitizers: ["script"],
    };
  },
  computed: {
    hdstyle() {
      const style = Math.floor(Math.random() * 4) + 1;
      return style;
    },
    lastWord() {
      let words = this.page.title
      let n = words.trim().split(" ");
      let second = n.pop()
      let first = n.join(" ");
      return '<small>'+first+'</small><br>'+second
    },
    content() {
      function renderParams(token) {
        if (token) {
          if (Object.keys(token)) {
            return (" " +
              Object.entries(token)
                .map((pair) => {
                  let value = pair[1];
                  let key = ":" + pair[0];
                  if (typeof pair[1] === "string") {
                    value = `"${value}"`;
                    key = pair[0];
                  }
                  return `${key}=${value}`;
                })
                .join(" "));
          }
        }
        return "";
      }
      function renderComponent(c) {

        if (c.shortcode=='graduates'){
          c.attributes={}
c.attributes.children=`${encodeURIComponent(JSON.stringify(c.children))}`

        }

        const content = c.content ? c.content : "";
        const params = c.attributes ? renderParams(c.attributes) : "";
        const children = c.children
          ? c.children.map(renderComponent).join("")
          : "";
        //  const content = c.content?c.content:''
        //console.log('----',c.shortcode)

        return `<component :is=${c.shortcode} ${params}>${content}${children}</component>`;
      }
      const re = new RegExp(`\\[(\\]?)([a-z_-]+)(?![\\w-])([^\\]]*(?:\/(?!\\])[^\]\/]*)*?)(?:(\/)\\]|\\](?:((?:(?!^\[])[^\[])*(?:\\[(?!\\/\\2\\])(?:(?!a-z)[^\[])*)*)\\[\\/\\2\\])?)`, "gm");

      if (this.page.content) {
        // console.log('CONTENT: ', this.page.content)
        this.page.content = this.page.content.replace(/<i class="fas fa-check">(\n*)?<\/i>/gmi, '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2 text-green-600"><path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" /></svg>');
        this.page.content = this.page.content.replace(/<i class="fas fa-times">(\n*)?<\/i>/gmi, '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2 text-red-600"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>');
      }

      if ('--',this.page.content) {
       // console.log(this.page.uri)
        this.page.content = this.page.content.replace(/<i\s[^>]*?class="(fa\sfa-([a-z-]*)[^>]*?)">(\n*)<\/i>/gmi, `<fa class="$1" icon="$2"  />`);
        this.page.content = this.page.content.replace(/<!--(.*?)-->/gm, "");
        //fix icons
        //let route=this.page
        return this.page.content.replace(re, ($0) => {

          $0 = $0.replace(/accordion-item/gm, "accordion_item");

          $0 = $0.replace(/display-posts/gm, "display_posts");
          $0 = $0.replace(/user-submitted-posts/gm, "user_submitted_posts");
          $0 = $0.replace(/(\r\n|\n|\r)/gm, "");
          let res = parseShortcodes($0);
/*           if(res[0]?.shortcode){
          console.log(route.uri,res[0].shortcode)
          } */
          return res[0] ? renderComponent(res[0]) : "";
        });
      }
    },
    replaceShortCodes() {
      if (this.content)
        return {
          template: `<div>${this.content}</div>`,
          data() {
            return {
              course_info: "CourseInfoOld",
              course_info_new: "CourseInfo",
              course_info_virtual: "CourseInfoVirtual",
              reviews: "UIReviewsAlt",
              set_taster_session: "SetTasterSession",
              tefl_resources: "TeflResources",
              tefl_newsletter: "TeflNewsletter",
              user_browser_info: "UserBrowserInfo",
              online_course_info: "OnlineCourseInfo",
              go_overseas: "GoOverseas",
              podcast_player: "PodcastPlayer",
              go_abroad: "GoAbroad",
              display_posts: "DisplayPosts",
              tquk: "TQUK",
              hideusa: "HideUSA",
              showusa: "ShowUSA",
              tefl_callback: "TeflCallback",
              tefl_box: "TeflBox",
              tefl_virtual_video: "TeflVirtualVideo",
              tefl_internship_download: "InternshipDownload",
              img_mask: "ImageMask",
              tefl_button: "TeflButton",
              embed: "EmbedVideo",
              transcript: "Transcript",
              ///////// CART  /////////////////////////
              transaction_details: "CartTransactionDetails",
              book_online_course_button: "CartBookOnlineCourseButton",
              book_course_button: "CartBookCourseButton",
              tefl_resources: "CartDownloads",
              show_price: "CartPrice",
              ////////////////////////////////////////////
              ///////////// TABLES    //////////////////////
              tefl_extensions_table: "TablesTeflExtensionsTable",
              tefl_table_countries: "TablesTeflTableCountries",
              tefl_table_online: "TablesTeflTableOnline",
              tefl_comparison_table: "TablesComparisonTable",
              //////////////////////////////////////////////
              tefl_pricing_table: "CartTeflPricingTable",
              accordion: "Accordion",
              accordion_item: "AccordionItem",
              ////////////   CARDS    //////////////////////
              bundle_steps: "CardsBundleSteps",
              article_card: "CardsArticleCard",
              course_card_related_new: "CardsCourseCardRelatedNew",
              course_card_related: "CardsCourseCardRelated",
              course_card: "CardsCourseCountryCard",
              // new_custom_card: "CardsCourseCard",
              tefl_priority: "CardsTeflPriority",
              new_custom_card: "CardsNewCustomCard",
              podcast_cards: "CardsPodcastCards",
              podcast_card: "CardsPodcastCard",
              course_cards: "CardsCourseCards",
              new_card: "CardsNewCard",
              course_cards_online: "CardsCourseCardsOnline",
              course_cards_bundles: "CardsCourseCardsBundles",
              course_cards_combined: "CardsCourseCardsCombined",
              course_cards_diploma: "CardsCourseCardsDiploma",
              course_cards_advanced: "CardsCourseCardsAdvanced",
              course_cards_virtual: "CardsCourseCardsVirtual",
              course_cards_classroom: "CardsCourseCardsClassroom",
              course_cards_partnerships: "CardsCourseCardsPartnerships",
              tefl_location_map: "CardsLocationMap",
              tefl_locations_england: "CardsLocationsEngland",
              tefl_locations_wales: "CardsLocationsWales",
              tefl_locations_scotland: "CardsLocationsScotland",
              tefl_locations_ireland: "CardsLocationsIreland",
              tefl_locations_nireland: "CardsLocationsNIreland",
              course_cards_teo: "CardsCourseCardsTEOBundle",
              partnership_logos: "CardsPartnerships",
              accreditation_logos: "CardsAccreditation",

              //////////////////////////////////////////////
              ///////////  JOBS    /////////////////////////
              jobs: "JobsJobSearch",
              job_feed_country: "JobsJobFeedCountry",
              job_feed: "JobsJobFeed",
              job_card: "JobsJobCard",
              job_card_country: "JobsJobCardCountry",
              job_card_recommended: "JobsJobCardRecommended",
              tefl_jobs_box: "JobsJobBox",
              tefl_jobs_box_country: "JobsJobBoxCountry",
              job_feed_summercamp: "JobsJobFeedSummercamp",
              //////////////////////////////////////////////
              user_submitted_posts: "UserSubmitted",
              tefl_brochure_compact: "TeflBrochureCompact",
              /////////////   GUIDES ////////////////////
              tefl_europe_guide: "GuidesTeflEuropeGuide",
              tefl_world_guide: "GuidesTeflWorldGuide",
              tefl_online_guide: "GuidesTeflOnlineGuide",
              tefl_asia_guide: "GuidesTeflAsiaGuide",
              tefl_middleeast_guide: "GuidesTeflMiddleEastGuide",
              tefl_latin_america_guide: "GuidesTeflLatinAmericaGuide",
              //////////////////////////////////////////////
              /////////////    FORMS /////////////////////
              certificate_lookup: "FormsCertificateLookup",
              tefl_taster_signup: "FormsTeflTasterSignup",
              tefl_brochure: "FormsTeflBrochure",
              tefl_elt_signup: "FormsTeflEltSignup",
              login_form: "FormsLoginForm",
              submit_resume_form: "FormsSubmitResume",
              gravityform: "FormsGravity",
              reset_password: "FormsResetPassword",
              sharer_form: "FormsSharer",
              submit_job_form: "FormsSubmitJobForm",
              contact_us: "FormsContactUs",
              ////////////////////////////////////////////
              ///// BLOG  /////////////////
              blog_card_custom: "BlogCardCustom",
              caption: "BlogCaption",
              ////////////////////////
              layerslider: "europe",
              resumes: "europe",
              candidate_dashboard: "europe",
              metaslider: "europe",
              checkout: "europe",
              show_cart: "europe",
              course_locator: "europe",
              cart_spinner: "europe",
              membership: "membership",
              stripe_balance: "CartStripeBalance",
              job_dashboard: "europe",
              bg_block: "BgBlock",
              image_mask: "ImageMask",
              /////// BLOCKS ///////////////////
              block: "BlocksBlock",
              color_block: "BlocksColorBlock",
              img_bg_block: "BlocksImageBgBlock",
              box: "BlocksBox",
              boxout: "BlocksBoxout",
              boxout_yt: "BlocksBoxoutYoutube",
              btn_block: "BlocksBtnBlock",
              faqs: "BlocksFaqs",
              faq: "BlocksFaq",
              img_block: "BlocksImgBlock",
              list_box: "BlocksListBox",
              list_item: "BlocksListItem",
              tab: "BlocksTab",
              tab_menu: "BlocksTabMenu",
              tab_content: "BlocksTabContent",
              half: "BlocksHalf",
              half_last: "BlocksHalfLast",
              third: "BlocksThird",
              third_last: "BlocksThirdLast",
              infobox: "BlocksInfoBox",
              icon_grid: "BlocksIconGrid",
              cards: "BlocksCards",
              card: "BlocksCard",
              download_button: "BlocksDownloadButton",
              download_box: "BlocksDownloadBox",
              /////////////////////////////////////
              /////// Sliders ////////
              tefl_slider: "SlidersSlider",
              tefl_slider_start: "SlidersSliderStart",
              tefl_slider_end: "SlidersSliderEnd",
              ///////////////////
              /////// Course Pages ////////
              whytefl: "CoursesWhy",
              tab_container: "CoursesTabsContainer",
              modules_menu: "CoursesModulesMenu",
              modules_tab: "CoursesModulesTab",
              modules_content_container: "CoursesModulesContentContainer",
              modules_content: "CoursesModulesContent",
              course_accreditation: "CoursesAccreditation",
              career: 'CoursesCareer',
              buy_now_blue: "CoursesBookNowAlt",
              graduates: "CoursesGraduates",
              graduate_testimonial: null,
              course_faqs: "CoursesFAQ",
              course_reviews: "CoursesReviews",
              buy_now: "CoursesBookNow",
              modal_faqs: "CoursesModalFAQs",
              useful_posts: "CoursesUsefulPosts",
              purchase_button: "CoursesPurchaseButton",
              vocal_video: "CoursesVocalVideo",
              course_quiz: "CoursesCourseQuiz",
              course_quiz_modal: "CoursesCourseQuizModal",
              course_quiz_button: "CoursesCourseQuizButton",
              youtube: "CoursesYoutube",
              whybox: "CoursesWhybox",
              cert_image: "CoursesCertImage",
              ///////////////////
              recommend: "BlocksRecommend",
              recommend_card: "BlocksRecommendCard",
              virtual_toggle: "VirtualToggle",
              team_member: "TeamMember",
              display_price: "DisplayPrice",
              classroom_modal: "CoursesClassroomModal",
              show_more: "ShowMore",
              form_reviews_banner: "FormReviewsBanner",
              form_reasons: "FormReasons",
              slide: "SlidersSlide",
              tefl_promos: "TeflPromos",
              message_box: "MessageBox",
              video_submission: "UIVideoButton",
              overview_why: "UIOverWhy",
              overview_jobs: "UIOverJobs",
              overview_accreditation: "UIOverAccreditation",
              accreditation: "SchemaAccreditation",
            };
          },
        };
    },
  }
}
