
    export default {
        props: {
          page: {
            type: Object,
            default() {
                return {}
            }
          },
        },
        computed: {
          headerImage() {
            // console.log('THUMBNAIL',thumbnail)
            const dotIndex = this.page.thumbnail.lastIndexOf('.');
            const ext = this.page.thumbnail.substring(dotIndex);
            const options = {quality:70, width: '700px'}
            const size =  this.page.headerSize || 'cover'
            const position = this.page.headerPosition || '40% 50%'
            if (ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG' ){
              options.format = 'webp'
            }
            console.log('THUMBNAIL',this.page.thumbnail,options)
            const imgUrl = this.$img(this.$options.filters.cdn(this.page.thumbnail), options)
            return {
                backgroundPosition: position,
                backgroundSize: size,
                backgroundImage: `url('${imgUrl}')`
            }
            
          },
          mask() {
            const mask = this.page.mask || 'https://cdn-wp.tefl.org/wp-content/uploads/2024/07/color-header-mask-2.png'
            return mask
          }
        }
    }
