import { render, staticRenderFns } from "./_.vue?vue&type=template&id=563ef06c&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"
import style0 from "./_.vue?vue&type=style&index=0&id=563ef06c&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoursesHeader: require('/opt/build/repo/components/Courses/Header.vue').default,BlocksNewHeader: require('/opt/build/repo/components/Blocks/NewHeader.vue').default,BlocksPodcastOverview: require('/opt/build/repo/components/Blocks/PodcastOverview.vue').default,BlocksPodcastEpisode: require('/opt/build/repo/components/Blocks/PodcastEpisode.vue').default,BlocksColourHeader: require('/opt/build/repo/components/Blocks/ColourHeader.vue').default,BlocksColourHeaderMask: require('/opt/build/repo/components/Blocks/ColourHeaderMask.vue').default,UIDifferentiation: require('/opt/build/repo/components/UI/Differentiation.vue').default,UIBreadcrumb: require('/opt/build/repo/components/UI/Breadcrumb.vue').default})
