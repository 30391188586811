
export default {
  props: {
    sellingpoints: {
      type: Array,
      default: () => [
        "<strong>Most accredited</strong><br> TEFL course provider",
        "<strong>Internationally "+this.recognised+"</strong><br>TEFL certificate",
        "<strong>Over" + this.$options.filters.noStudents('140,000') + "</strong><br>teachers trained",
        "<strong>80% of graduates</strong><br>find work within 2 months"
      ]
    },
    region:{
      type:String,
      default:'UK',
    },
  },
  computed: {
     sellingPointsFiltered (){
      let filtered = this.sellingpoints.filter(element => {
          return element !== '';
      })
      if (filtered.length === 0){
        // SET DEFAULTS
        filtered=[
          "<strong>Most accredited</strong><br> TEFL course provider",
          "<strong>Internationally "+this.recognised+"</strong><br>TEFL certificate",
          "<strong>Over " + this.$options.filters.noStudents('140,000') + "</strong><br>teachers trained",
          "<strong>80% of graduates</strong><br>find work within 2 months"
        ]
      }
      return filtered
     },
     recognised() { 
      return this.region === 'UK' ? 'recognised' : 'recognized'
     }
  }
}
