   /* eslint-disable */
   const regex = /\[[a-zA-Z0-9_\/-]+(?:\s+[^="]+="[^"\]]+")*\]/g; //original /\[[a-zA-Z_\/-]+(?:\s+[^="]+="[^"\]\s]+")*\]/g
   /* matches code name */
   const RX_KEY = '[a-zA-Z_]+'

   /* matches paramters */
   const RX_PARAM = RX_KEY + '=\\d+\\.\\d+' + // floats
     '|' + RX_KEY + '=\\d+' + // ints
     '|' + RX_KEY + '=true|false|yes|no' + // bools
     '|' + RX_KEY + '="[^\\]"]*"' + // double-qouted strings
     '|' + RX_KEY + '=\'[^\\]\']*\'' + // single-qouted strings
     '|' + RX_KEY // flags 
   const RX_PARAMS = RX_PARAM + '(?:(?!\\s+\\/?\\])\\s|)+'

   const rxParams = new RegExp(RX_PARAMS.substring(0, RX_PARAMS.length - 1), 'ig')

   function initParams(paramStr) {


     const match = paramStr.match(rxParams)

     const params = match.reduce((params, paramToken) => {

       paramToken = paramToken.trim()

       let equal = paramToken.indexOf('=')
       if (!~equal) {
         params[paramToken] = true
       } else {
         params[paramToken.substring(0, equal)] = castValue(paramToken.substring(equal + 1))
       }

       return params
     }, {})
     return params
   };

   function castValue(value) {
     if (/^\d+$/.test(value)) return Number(value)
     if (/^\d+.\d+$/.test(value)) return Number(value)
     if (/^(true|false|yes|no)$/i.test(value)) {
       value = value.toLowerCase()
       return value === 'true' || value === 'yes'
     }
     return value.replace(/(^['"]|['"]$)/g, '')
   }
   module.exports = function parseShortcodes(str) {
     var m;
     var result = [];
     let nodeID = 0;

     //process the string
   while ((m = regex.exec(str)) !== null) {

       let shortcode = {}; //will store the resulting shortcode and its attributes
       let stringEnd = regex.lastIndex;

       let shortcodeStart = m.index;

       if (m.index === regex.lastIndex) {
         regex.lastIndex++;
       }
       let shortcodeInner = m[0].slice(1, -1); //remove the brackets

       if (shortcodeInner.indexOf(" ") !== -1) {

         //has attributes ex. [shortcode attribute="something"]...
         shortcode.shortcode = shortcodeInner.substring(0, shortcodeInner.indexOf(" "));
         let shortcodeAttsRaw = shortcodeInner.substring(shortcodeInner.indexOf(" "));

         shortcode.attributes = initParams(shortcodeAttsRaw)
       } else {
         //does not have attributes ex. [shortcode]...
         shortcode = {
           shortcode: shortcodeInner
         };
       }
       shortcode.se = stringEnd; //position in content where this shortcode ends

       //closing or opening tags
       if (shortcode.shortcode[0] === '/') {
         //closing tag, find opening tag
         for (let i = result.length - 1; i >= 0; i--) {
           if (result[i].shortcode === shortcode.shortcode.substring(1)) {
             //found result
             if (i === (result.length - 1)) {
               //shortcodes with content eg. [shortcode]some text[/shortcode]
               //set content as a separate key
               result[i].content = str.slice(result[i].se, shortcodeStart);

             } else {
               //nested shortcodes eg. [shortcode1][other_shortcode][/shortcode1]
               //set contained tags as children of this shortcode
               result[i].children = result.slice(i + 1);
               result[i].content =  str.slice(result[i].se, shortcodeStart).replace(/\[(.*)\]/g,'')
               result = result.slice(0, i + 1);
             }
             break; //only need 1 item
           }
         }
       } else {
         //opening tag, just add it
         shortcode.nodeID = nodeID;
         result.push(shortcode);
         nodeID++;
       }
     }
// console.log(result)
     return  result;
   };
