
    import { mapGetters,mapActions } from 'vuex'
    export default {
        props: {
          page: {
            type: Object,
            default() {
                return {}
            }
          },
        },
        data() {
        return {
            isActive: this.active !== ''
        }
    },
    computed: {
        ...mapGetters({
            activeModal : 'pages/getActiveModal',
        }),
        headerImage() {
            // console.log('THUMBNAIL',thumbnail)
            const dotIndex = this.page.thumbnail.lastIndexOf('.');
            const ext = this.page.thumbnail.substring(dotIndex);
            const options = {quality:60, width: '700px'}
            if (ext === '.jpg' || ext === '.JPG' || ext === '.jpeg' || ext === '.JPEG' ){
                options.format = 'webp'
            }
            console.log('THUMBNAIL',this.page.thumbnail,options)
            const imgUrl = this.$img(this.$options.filters.cdn(this.page.thumbnail), options)
            return {
                backgroundImage: `url('${imgUrl}')`
            }
        },
    },
    methods: {
        ...mapActions({
            updateActiveModal: 'pages/updateActiveModal', // map `this.add()` to `this.$store.commit('increment')`
        }),
        updateModal(id){
            const newId = this.$nuxt.$options.filters.convertModal(id)
            this.updateActiveModal(newId)
            console.log('Active Modal: ',this.activeModal)
        },
    }
    
    }
